import React, { useCallback, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { MediumSpacePaddedRow, NoMarginGrid } from 'components/ui/Grid';
import Header from 'components/ui/Header';
import { LimitedTextCell } from 'components/ui/Text';
import ExportAsXlsButton from 'components/ui/button/export-as/ExportAsXls';
import SentimentCell from 'components/ui/cells/SentimentCell';
import { I18nDropdown } from 'components/ui/inputs/Dropdown';
import { TextInput } from 'components/ui/inputs/TextInput';
import useTableFilter from 'components/ui/search/useTableFilter';
import ReactTable from 'components/ui/table/ReactTableUi';

import commonPropTypes from 'utils/commonPropTypes';
import { zeroPrecisionPercentFormatter } from 'utils/formatter';
import { accentFold, capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

export const COMPETITION_ONTOLOGY_TABLE_SORT_OPTIONS = [
  {
    key: 'cotso-1',
    value: 'shareOfExtracts',
    i18nText: t`base-group-share-of-extracts`,
  },
  {
    key: 'cotso-2',
    value: 'competitionShareOfExtracts',
    i18nText: t`comparative-groups-share-of-extracts`,
  },
  {
    key: 'cotso-3',
    value: 'differentialSentiment',
    i18nText: t`differential-sentiment`,
  },
];

export const CellKpiContainer = styled.span`
  width: 34.3%;
  text-align: left;
  font-weight: ${svars.fontWeightMedium};
  color: ${svars.fontColorBase};
`;
const sentimentIconStepSpacePx = 4;
function SentimentIcon({ style }) {
  return (
    <Icon.Group
      style={{
        display: 'inline-table',
        minWidth: '25px',
        fontSize: '18px',
        ...style,
      }}
    >
      <Icon
        name="map pin"
        style={{
          marginLeft: 0,
          marginRight: 0,
          color: svars.absoluteMinColor,
          width: 'auto',
        }}
      />
      <Icon
        name="map pin"
        style={{
          marginLeft: `${sentimentIconStepSpacePx}px`,
          left: '25%',
          width: 'auto',
          color: svars.absoluteMidColor,
        }}
      />
      <Icon
        name="map pin"
        style={{
          left: '35%',
          width: 'auto',
          marginLeft: `${2 * sentimentIconStepSpacePx}px`,
          color: svars.absoluteMaxColor,
        }}
      />
    </Icon.Group>
  );
}

SentimentIcon.propTypes = {
  style: commonPropTypes.style,
};
SentimentIcon.defaultProps = {
  style: {},
};

export function BenchmarkKpis({
  differentialSentiment,
  shareOfExtracts,
  competitionShareOfExtracts,
  centered,
  style,
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: centered ? 'center' : 'flex-start',
        alignItems: 'center',
        width: '100%',
        ...style,
      }}
    >
      <CellKpiContainer>
        <Icon name="cube" color="orange" style={{ lineHeight: '1.7rem' }} />
        {zeroPrecisionPercentFormatter(shareOfExtracts)}
      </CellKpiContainer>
      <CellKpiContainer>
        <Icon
          name="cubes"
          color="orange"
          style={{ fontSize: svars.fontSizeXLarge }}
        />
        {competitionShareOfExtracts !== undefined
          ? zeroPrecisionPercentFormatter(competitionShareOfExtracts)
          : '-'}
      </CellKpiContainer>
      <CellKpiContainer>
        <SentimentIcon style={{ paddingRight: svars.spaceSmall }} />
        <SentimentCell sentiment={differentialSentiment} />
      </CellKpiContainer>
    </div>
  );
}

BenchmarkKpis.propTypes = {
  differentialSentiment: PropTypes.number,
  // Whether the kpis should be centered or not
  centered: PropTypes.bool,
  style: commonPropTypes.style,
};
BenchmarkKpis.defaultProps = {
  centered: false,
  differentialSentiment: undefined,
  style: {},
};

function ConceptBenchmarkCell({
  name,
  centered,
  directed,
  selected,
  ...props
}) {
  const isSelectedColor = selected ? svars.accentColor : 'inherit';
  return (
    <div
      selected={selected}
      style={{ boxShadow: 'none', width: '100%', overflowX: 'hidden' }}
    >
      <Header
        style={{
          display: 'inline-flex',
          width: '100%',
          justifyContent: 'space-between',
          padding: 0,
        }}
      >
        <LimitedTextCell
          style={{
            marginRight: svars.spaceNormal,
            color: isSelectedColor,
          }}
        >
          {name}
        </LimitedTextCell>
        {directed && (
          <Icon
            name="circle chevron right"
            style={{ color: isSelectedColor, fontSize: '1rem' }}
          />
        )}
      </Header>
      <BenchmarkKpis centered={centered} {...props} />
    </div>
  );
}

ConceptBenchmarkCell.propTypes = {
  ...BenchmarkKpis.propTypes,
  name: PropTypes.string.isRequired,
  // Whether a chevron icon should be displayed next to cell name
  directed: PropTypes.bool,
};

ConceptBenchmarkCell.defaultProps = {
  ...BenchmarkKpis.defaultProps,
  directed: true,
};

function TableLegendItem({ icon, label }) {
  return (
    <Grid.Column style={{ display: 'flex', margin: `${svars.spaceSmall} 0` }}>
      <span style={{ alignSelf: 'center', display: 'flex', minWidth: '25px' }}>
        {icon}
      </span>
      <span
        style={{
          fontSize: svars.fontSizeSmall,
          paddingLeft: svars.spaceSmall,
          lineHeight: svars.lineHeightLarge,
          alignSelf: 'center',
        }}
      >
        {label}
      </span>
    </Grid.Column>
  );
}

TableLegendItem.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

function ConceptCell({ row }) {
  return <ConceptBenchmarkCell name={row.values.name} {...row.original} />;
}

ConceptCell.propTypes = {
  row: PropTypes.shape({
    values: PropTypes.shape({ name: PropTypes.string }),
    original: PropTypes.shape({
      differentialSentiment: PropTypes.number,
    }),
  }).isRequired,
};

const BenchmarkNavigationTable = styled(ReactTable)`
  margin-top: ${svars.spaceNormalLarge};
  border-radius: 0;
  overflow: hidden;
  &&& div > div > div > div {
    width: 100%;
    padding: ${svars.spaceSmall};
    justify-content: stretch;
    & div > div {
      border: none;
    }
  }
`;

const exportColumns = [
  { key: 'name', label: t`name`, width: 50 },
  { key: 'shareOfExtracts', label: t`share-of-extracts`, width: 20 },
  { key: 'differentialSentiment', label: t`differential-sentiment`, width: 20 },
  {
    key: 'competitionShareOfExtracts',
    label: t`comparative-groups-share-of-extracts`,
    width: 20,
  },
];

const TABLE_COLUMNS = [
  {
    accessor: 'name',
    id: 'name',
    maxWidth: 600,
    Cell: ConceptCell,
    filterMethod: (filter, row) => {
      if (!filter) return false;
      const accentFolded = accentFold(row.name.toLowerCase());
      return accentFolded.includes(accentFold(filter.value.toLowerCase()));
    },
  },
  // Columns share of extracts and sentiment are not displayed but used for sorting rows
  {
    accessor: 'shareOfExtracts',
    show: false,
    sortType: 'basic',
  },
  {
    accessor: 'differentialSentiment',
    show: false,
    sortType: 'basic',
  },
  {
    accessor: 'competitionShareOfExtracts',
    show: false,
    sortType: 'basic',
  },
];

function CompetitionOntologyTable({
  data,
  selectedConceptId,
  tableSortedColumn,
  onTableSort,
  onSelectOntologyConcept,
}) {
  const [tableFiltered, setTableFiltered] = useState([]);

  const [filteredData, textFilterValue, setTextFilterValue] = useTableFilter(
    data,
    'name',
    []
  );
  const defaultSorted = useMemo(
    () => [{ id: tableSortedColumn, desc: true }],
    [tableSortedColumn]
  );
  const onFilterChange = useCallback((e, { value }) => {
    setTextFilterValue({ target: { value } });
    setTableFiltered([
      {
        id: 'name',
        value,
      },
    ]);
  }, []);
  return (
    <NoMarginGrid>
      <Grid.Row
        style={{
          padding: `${svars.spaceMedium} ${svars.spaceNormal} 0 ${svars.spaceNormal}`,
        }}
      >
        <Grid.Column as={Header} width={14}>
          <Trans id="competitive-analysis" />
        </Grid.Column>
        <Grid.Column as={Header} width={2} textAlign="right">
          <ExportAsXlsButton
            exportName={t`competitive-analysis`}
            data={data}
            disabled={!data?.length}
            columns={exportColumns}
          />
        </Grid.Column>
      </Grid.Row>
      <MediumSpacePaddedRow>
        <Grid.Column>
          <TextInput
            icon="search"
            style={{ width: '100%' }}
            placeholder={capitalize(t`search-a-category`)}
            onChange={onFilterChange}
            value={textFilterValue}
          />
        </Grid.Column>
      </MediumSpacePaddedRow>
      <MediumSpacePaddedRow>
        <Grid.Column style={{ display: 'inline-flex', alignItems: 'center' }}>
          <span>{capitalize(t`order-by`)} :</span>
          <span style={{ marginLeft: svars.spaceMedium, flexGrow: 1 }}>
            <I18nDropdown
              selection
              deburr
              options={COMPETITION_ONTOLOGY_TABLE_SORT_OPTIONS}
              onChange={onTableSort}
              value={tableSortedColumn}
              placeholder="-"
            />
          </span>
        </Grid.Column>
      </MediumSpacePaddedRow>
      <MediumSpacePaddedRow columns={1} style={{ paddingBottom: 0 }}>
        <TableLegendItem
          icon={
            <Icon
              name="cube"
              color="orange"
              style={{ lineHeight: '1.7rem', height: '100%' }}
            />
          }
          label={capitalize(t`base-group-share-of-extracts`)}
        />
        <TableLegendItem
          icon={
            <Icon
              name="cubes"
              color="orange"
              style={{ fontSize: svars.fontSizeXLarge, margin: 'auto' }}
            />
          }
          label={capitalize(t`comparative-groups-share-of-extracts`)}
        />
        <TableLegendItem
          style={{ margin: 'auto' }}
          icon={<SentimentIcon />}
          label={capitalize(t`differential-sentiment`)}
        />
      </MediumSpacePaddedRow>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column style={{ padding: 0 }}>
          <BenchmarkNavigationTable
            noHeader
            data={filteredData || []}
            loading={data === undefined}
            noDataText={capitalize(t`no-data-available`)}
            loadingText={`${capitalize(t`loading`)} ...`}
            showPagination={false}
            defaultSorted={defaultSorted}
            pageSize={data ? data.length : 20}
            filtered={tableFiltered}
            selectedRow={{ conceptId: selectedConceptId }}
            rowKey="conceptId"
            columns={TABLE_COLUMNS}
            onRowClick={(row) => onSelectOntologyConcept(row.conceptId)}
            style={{ height: '62vh' }}
          />
        </Grid.Column>
      </Grid.Row>
    </NoMarginGrid>
  );
}

CompetitionOntologyTable.propTypes = {
  data: commonPropTypes.timeSeries,
  selectedConceptId: PropTypes.string,
  onSelectOntologyConcept: PropTypes.func.isRequired,
  tableSortedColumn: PropTypes.string.isRequired,
  onTableSort: PropTypes.func.isRequired,
};

CompetitionOntologyTable.defaultProps = {
  data: undefined,
  selectedConceptId: undefined,
};

export default React.memo(CompetitionOntologyTable);
